export default {
  error_occurred: 'Вибачте, сталася помилка. Спробуйте ще раз за мить.',
  new_content_available: 'Сторінка оновлена, натисніть «Оновити», щоб оновити сторінку.',
  refresh_button_title: 'Оновити',
  force_reload_message: 'Вибачте, сталася помилка. Наша команда вже працює над цим. Спробуйте оновити веб-сайт через мить.',
  sign_up: 'Створити аккаунт',
  service: 'Сервіс',
  employee: 'Співробітник',
  select_employee: 'Виберіть людину',
  employees: 'Співробітники',
  services: 'Послуги',
  duration: 'Тривалість',
  from: 'Від',
  select_date: 'Виберіть дату',
  no_account_question: 'У вас ще немає облікового запису?',
  name: "Ім'я",
  surname: 'Прізвище',
  name_and_surname: "Повне ім'я",
  phone: 'Телефон',
  password: 'Пароль',
  field_is_required: "Це поле є обов'язковим.",
  value_is_too_long: 'Значення задовге.',
  value_is_too_short: 'Значення закоротке.',
  signup_was_successful: 'Реєстрація пройшла успішно. Вхід в панель.',
  signup_has_failed: 'Помилка реєстрації. Спробуйте ще раз за мить.',
  email_is_taken: 'Обліковий запис із вказаною електронною адресою вже існує. Якщо ви забули пароль, скористайтеся опцією нагадування.',
  email_or_password_incorrect: 'Адреса електронної пошти або пароль неправильні. Якщо ви забули пароль, скористайтеся опцією нагадування.',
  account_not_found: 'Обліковий запис не знайдено.',
  page_not_found: 'Ой! Сторінку, яку ви шукали, не вдалося знайти :(',
  go_back_to_main_page: 'Поверніться на головну сторінку та повторіть спробу.',
  bookings: 'Бронювання',
  account: 'Мій рахунок',
  date: 'Дата',
  cancel: 'Скасувати',
  date_from: 'Дата з',
  date_to: 'Дата до',
  nothing_found_here: 'Нічого не знайдено.',
  old_password: 'Старий пароль',
  remove: 'Видалити',
  phone_is_invalid: 'Номер телефону неправильний.',
  book: 'Книга',
  sign_up_2: 'Створити аккаунт',
  log_in: 'Увійти',
  your_email_address: 'Ваша електронна адреса',
  select: 'Виберіть',
  filters: 'Фільтри',
  expand: 'Розгорнути',
  collapse: 'Згорнути',
  bookings_drawer_title: 'Виберіть фільтри, щоб відобразити лише ті бронювання, які ви шукаєте.',
  clear_filters: 'Скинути фільтри',
  show_more: 'Показати більше',
  dont_you_remember_password: 'Забули свій пароль?',
  password_information: 'Пароль має складатися не менше ніж з 8 символів, містити велику літеру, цифру та спеціальний символ.',
  forgotten_password_button: 'Надішліть посилання для зміни пароля',
  do_you_already_have_an_account: 'Ви вже маєте акаунт?',
  reset_password_title: 'Встановити новий пароль',
  reset_password_subtitle: 'Введіть свій новий пароль нижче.',
  reset_password_button: 'Збережіть новий пароль',
  profile: 'Профіль',
  logout: 'Вийти з аккаунта',
  cancel_booking: 'Скасувати бронювання',
  status_approved: 'Затверджено',
  status_payment: 'В очікуванні оплати',
  status_done: 'Виконано',
  status_cancelled: 'Скасовано',
  cancel_booking_dialog_title: 'Бажаєте скасувати бронювання?',
  cancel_booking_dialog_information: 'Цю дію неможливо скасувати!',
  cancel_booking_dialog_button: 'Так, скасувати моє бронювання',
  my_profile: 'Мій профіль',
  my_data: 'Мої дані',
  upload_new_photo: 'Додайте нове фото',
  birthday: 'день народження',
  save_changes: 'Зберегти зміни',
  phone_number: 'Телефонний номер',
  directional: 'Код зони',
  save_new_password: 'Збережіть новий пароль',
  new_password: 'Новий пароль',
  password_change: 'Змінити пароль',
  view: 'Переглянути',
  services_2: 'Послуги',
  accept_terms_and_conditions_1: 'Натискаючи опцію нижче, я погоджуюся з',
  accept_terms_and_conditions_2: 'Правила та умови',
  accept_terms_and_conditions_3: 'і я підтверджую, що прочитав',
  accept_terms_and_conditions_4: 'Заява про конфіденційність.',
  to: 'до',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Увійти',
  email_is_incorrect: 'Адреса електронної пошти неправильна.',
  logged_out: 'Ви вийшли з системи',
  booking_dialog_date_is_already_taken: 'Вибраний час недоступний. Чи не проти вибрати інше місце?',
  data_search: 'Пошук даних. Будь ласка, зачекайте...',
  no_employees: 'Немає працівників',
  no_services: 'Без послуг',
  no_categories: 'Без категорій',
  no_bookings: 'Бронювання не знайдено.',
  no_subscriptions: 'Підписок не знайдено.',
  female: 'Жінка',
  male: 'Чоловік',
  other: 'Інший',
  no_numeric_in_password: 'У паролі відсутні цифрові символи.',
  no_lowercase_in_password: 'У паролі немає малої літери.',
  no_uppercase_in_password: 'У паролі немає великої літери.',
  no_special_character: 'Без особливого символу.',
  min_n_characters: 'Мінімальна кількість символів – @{min}.',
  max_n_characters: 'Максимальна кількість символів – @{max}.',
  email: 'Електронна пошта',
  reset_password_fail_text: 'Пароль не змінено. Ймовірно, термін дії посилання закінчився.',
  reset_password_success_title: 'Ваш пароль був змінений.',
  reset_password_success_text: 'Ви можете увійти за допомогою нового пароля.',
  account_activation: 'Активація облікового запису',
  account_activation_success: 'Обліковий запис активовано.',
  account_activation_fail: 'Під час активації облікового запису виникла проблема. Схоже, обліковий запис уже активний або посилання вже недійсне.',
  account_activation_processing: 'Активація вашого облікового запису. Будь ласка, зачекайте...',
  account_activation_resend_title: 'Обліковий запис не активовано',
  account_activation_resend_text: 'Активуйте свій обліковий запис, натиснувши посилання для активації в електронному листі, який ми вам надіслали.',
  account_activation_resend_button: 'Надіслати знову',
  confirm_send_activate_link_dialog_subtitle: 'Ми надіслали електронний лист із посиланням для активації на вказану раніше електронну адресу.',
  file_is_too_large: 'Вибраний файл завеликий.',
  appointment_title: 'Виберіть особу, дату та час бронювання',
  others: 'Інший',
  countryLabel: 'Країна',
  requiredMessages: 'Необхідно вказати номер телефону',
  date_of_visit: 'Дата',
  hour_of_visit: 'час',
  thank_you_for_booking: 'Ваше бронювання',
  no_free_hours: 'У вибраний день немає доступних часових інтервалів.',
  any_person: 'Випадковий вибір',
  password_changed: 'Ваш пароль був змінений',
  incorrect_old_password: 'Старий пароль неправильний',
  error404: 'Помилка 404',
  minutes: 'хв',
  hours: 'ч',
  days: 'd',
  ok: 'Ok',
  web_page_temporary_disabled: 'Вибачте! Ця сторінка була тимчасово вимкнена.',
  profile_update_success: 'Ваші дані збережено.',
  form_is_invalid: 'Форма містить помилки. Перевірте виділені поля та повторіть спробу.',
  search: 'Пошук',
  pay_online_button_title: 'Оплата онлайн',
  account_not_activated_info: 'Адреса електронної пошти не підтверджена. Підтвердьте свою адресу електронної пошти, натиснувши посилання в надісланому повідомленні.',
  paid: 'Платний',
  payments: 'Платежі',
  payment_service: 'Сервіс',
  payment_date: 'Дата',
  payment_price: 'Ціна',
  payment_success_title: 'Оплата пройшла успішно, дякуємо!',
  payment_fail_title: 'Не вдалося здійснити платіж, повторіть спробу.',
  pay_again: 'Платіть знову',
  newsletter_subscribe_success: 'Дякую! Вашу електронну адресу підтверджено.',
  newsletter_subscribe_fail: 'Під час активації адреси електронної пошти виникла проблема. Схоже, адреса електронної пошти вже активна або посилання вже недійсне.',
  newsletter_send_again: 'Надіслати знову',
  newsletter_send_again_success: 'Посилання для активації надіслано повторно.',
  send_again_fail: 'Під час створення нового посилання для активації виникла проблема. Можливо, ваша електронна адреса вже активна або ви щойно створили посилання.',
  newsletter_email_is_used: 'Адреса електронної пошти вже є в списку, якщо адреса не була підтверджена, ми можемо повторно надіслати посилання для активації.',
  newsletter_unsubscribe_success: 'Дякую! Вашу електронну адресу видалено.',
  newsletter_unsubscribe_fail: 'Під час видалення адреси електронної пошти виникла проблема. Схоже, адресу електронної пошти вже видалено або термін дії посилання закінчився.',
  booking_payments: 'Бронювання',
  simple_store_product_transactions: 'Продукти',
  payment_type_select_box: 'Виберіть тип оплати',
  payment_description: 'опис',
  add_to_cart: 'Додати в кошик',
  remove_from_cart: 'Зняти з кошика',
  save: 'зберегти',
  bookings_cart_summary: 'Резюме бронювання',
  today: 'Сьогодні',
  fill_booking_details_title: 'Введіть деталі',
  create_an_account_question: 'Створіть обліковий запис і надішліть пароль на адресу електронної пошти',
  value_is_incorrect: 'Це значення недійсне.',
  first_available_date: 'Доступні часові інтервали включені',
  service_location_1: 'Адреса',
  service_location_2: 'Телефон',
  service_location_3: 'Google Meet',
  service_location_4: 'Інший',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Місцезнаходження',
  booking_not_found: 'Бронювання не знайдено.',
  booking_canceled: 'Бронювання скасовано.',
  booking_canceled_fail: 'Це бронювання не можна скасувати.',
  day: 'День',
  week: 'тиждень',
  month: 'Місяць',
  year: 'Рік',
  booking_plural_1: 'Бронювання',
  booking_plural_2: 'Бронювання',
  booking_plural_3: 'Бронювання',
  booking_plural_4: 'Бронювання',
  booking_plural_5: 'Бронювання',
  day_plural_1: 'День',
  day_plural_2: 'днів',
  day_plural_3: 'днів',
  day_plural_4: 'днів',
  day_plural_5: 'днів',
  week_plural_1: 'тиждень',
  week_plural_2: 'тижнів',
  week_plural_3: 'тижнів',
  week_plural_4: 'тижнів',
  week_plural_5: 'тижнів',
  month_plural_1: 'Місяць',
  month_plural_2: 'Місяці',
  month_plural_3: 'Місяці',
  month_plural_4: 'Місяці',
  month_plural_5: 'Місяці',
  year_plural_1: 'Рік',
  year_plural_2: 'Років',
  year_plural_3: 'Років',
  year_plural_4: 'Років',
  year_plural_5: 'Років',
  tax: 'Ідентифікаційний номер платника ПДВ',
  tax_prefix: 'Префікс',
  get_company_data: 'Пошук',
  get_company_data_failed: 'Не вдалося завантажити дані.',
  company_name: 'Назва компанії',
  street_and_number: 'Вулиця і номер',
  postcode: 'Поштовий індекс',
  city: 'Місто',
  country: 'Країна',
  subscribe: 'Підпишіться',
  recurrent_fee_info: 'Рахунок виставляється кожні',
  one_time_fee_info: 'Одноразова плата.',
  new_account_title: 'Створити новий акаунт',
  payment_data_title: 'Платіжні реквізити',
  billing_data_checkbox_question: 'Платіжні реквізити',
  confirmation_modal_header: 'Дія потребує підтвердження',
  confirmation_modal_warning: 'Ви впевнені, що бажаєте продовжити?',
  no_way_to_undo_action: 'Цю дію не можна скасувати!',
  delete_payment_method: 'Видалити картку',
  add_payment_method: 'Додати картку',
  fill_card_details: 'Заповніть платіжні реквізити.',
  subscriptions: 'Підписки',
  subscription: 'Підписка',
  invoice_number: 'рахунок-фактура',
  number: 'Номер',
  status: 'Статус',
  details: 'Подробиці',
  created_at: 'Створено',
  subscription_status_initiated: 'Неоплачений',
  subscription_status_active: 'Активний',
  subscription_status_inactive: 'Неактивний',
  subscription_status_canceled: 'Скасовано',
  ends_at: 'Закінчується о',
  ends_at_default: 'Діє до кінця розрахункового періоду',
  select_subscription_to_pay: 'Виберіть підписку для оплати.',
  pay_with_subscription: 'Оплатіть за підпискою',
  bookings_paid_by_subscription_success_message: 'Платний',
  bookings_not_paid_by_subscription_error_message: 'Не вдалося оплатити бронювання за допомогою вибраної підписки.',
  stripe: 'Stripe',
  provider_tag: 'метод',
  card: 'Картка',
  cash: 'Готівка',
  transfer: 'Трансфер',
  promo_code_label: 'Код на знижку',
  promo_code_invalid_message: 'Наданий код знижки недійсний.',
  payment_method_setup_error: 'Виникла проблема з вашою карткою. Ваш банк відмовився авторизувати картку для подальших транзакцій. Будь ласка, зверніться до свого банку.',
  active_subscription_missing: 'Для бронювання цієї послуги потрібна активна підписка. На жаль, у вас немає жодної підписки.',
  area_code: 'Код зони',
  usage: 'Використання',
  create_account_in_stripe_failed: 'Платіжний оператор відхилив дані вашої підписки. Будь ласка, перевірте правильність заповнення форми (ідентифікаційний номер платника податків, номер телефону, адреса електронної пошти).',
  additional_auth_required: 'Ваш банк вимагає додаткової авторизації',
  general_payment_error: 'Виникла проблема з вашим способом оплати. Можливо, ваша карта не підтримує підписки. Зверніться до свого банку або виберіть інший спосіб оплати.',
  subscription_set_successfully: 'Правильно налаштована підписка.',
  booking_created: 'Нове бронювання',
  booking_updated: 'Зміна бронювання',
  booking_deleted: 'Видалення бронювання',
  booking_reminder: 'Нагадування про бронювання',
  email_notifications: 'Сповіщення електронною поштою',
  sms_notifications: 'СМС сповіщення',
  save_user_preferences: 'Зберегти налаштування',
  free_spots: 'Залишилися плями',
  no: 'Ні',
  yes: 'Так',
  p24_inactive: "Не можна використовувати платіжного оператора Przelewy24. Будь ласка, зв'яжіться зі своїм платіжним оператором.",
  stripe_customer_invalid: 'Недійсний ідентифікатор клієнта Stripe. Спосіб оплати не може бути прикріплений до клієнта.',
  stripe_resource_invalid: 'Вибраного товару немає в наявності. Наявність товару уточнюйте у платіжного оператора.',
  account_blocked_message: "Ваш акаунт неактивний. Будь ласка, зв'яжіться з адміністратором.",
  time_zone: 'Часовий пояс',
  no_spots_left: 'Ви досягли максимальної кількості доступних місць.',
  employee_auth_required: 'Увійдіть у свій обліковий запис або скористайтеся іншою адресою електронної пошти. Облікові записи співробітників вимагають входу.',
  subpage_auth_required: 'Увійдіть у свій обліковий запис, щоб переглянути вкладку.',
  gross: 'Сума брутто.',
  subscription_login_info: 'Вже є аккаунт? Будь ласка, увійдіть нижче, щоб заповнити свої дані.',
  change_date_time: 'Перепланувати',
  promo_code_checkbox: 'У мене є код знижки',
  booking_promo_code_label: 'Введіть код знижки',
  select_hour: 'Виберіть час',
  id: 'Id',
  booking_rescheduled: 'Ваше бронювання змінено.',
  booking_rescheduled_fail: 'Це бронювання не можна змінити.',
  max_user_bookings_error: 'Ви перевищили максимальну кількість замовлень. Виберіть інший сервіс або зв\'яжіться з нами.',
  in_total: 'Сума',
  company: 'Фірма',
  individual: 'Приватна особа',
  bulk_payment: 'Оптова оплата',
  make_bulk_payment: 'Оптова оплата',
  simple_store_product_confirmation_title: 'Заповніть форму покупки',
  select_service_type: 'Виберіть варіант послуги',
  add_to_calendar_button_label: 'Додати до календаря',
  login_to_use_service_with_subscription: 'Увійдіть у свій обліковий запис, щоб записатися на цю послугу.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Обраний термін недоступний. Будь ласка, виберіть інший вільний термін. У разі бронювання декількох термінів переконайтеся, що вони не виключають один одного.',
  select_location_calendar_warning: 'Виберіть місце, щоб завантажити доступні часи.',
  selectable_location_online_title: 'Онлайн',
  change: 'Змінити',
  show_less_hours_button: 'Показати менше годин',
  show_more_hours_button: 'Показати більше годин',
  add_another_button_title: 'Додати ще один',
  close: 'Закрити',
  selected_time_slots: 'Вибрані часові інтервали',
  select_location: 'Виберіть місце',
  captcha_invalid_error_notification: 'Перевірка captcha не вдалася. Спробуйте перезавантажити сторінку, якщо проблема зберігається.',
  verify_code_incorrect_error: 'Введений код неправильний.',
  verify_code_too_many_requests: 'Ми не можемо згенерувати новий код у цей момент. Спробуйте пізніше або використайте код, який ми вже надіслали.',
  send_again_button_title: 'Надіслати ще раз',
  verify_title: 'Підтвердити',
  verify_code_title: 'Введіть код підтвердження',
  verify_code_description: 'Ми надіслали 6-значний код підтвердження на вашу електронну пошту. Введіть його нижче:',
  verification_invalid_error_notification: 'Неправильна верифікація. Спробуйте пізніше.',
  too_many_requests: 'Ой! Ви дієте трохи надто швидко. Зупиніться на мить і спробуйте ще раз пізніше.',
  services_number_title: 'Кількість послуг на вибір'
}
